import * as PIXI from "./pixi";

const statuscontainer = document.querySelector("#status");
const setStatus = (status) => {
  statuscontainer.classList.toggle("visible", !!status);
  statuscontainer.innerHTML = status;
}
const alertStatus = (status, callback) => {
  setStatus(status);
  setTimeout(() => {
    setStatus(null);
    callback(); 
  }, 2000);
}
setStatus("click or touch to jump")

const container = document.querySelector("#container");
const CANVAS_SIZE = Math.min(
  document.body.clientHeight,
  document.body.clientWidth
);
const norm = (px) => (CANVAS_SIZE / 300) * px;

let numberOfPlatforms = 1;
let jumps = 0;
const jumpsContainer = document.querySelector("#jumps>.value");
const addJump = () => {
  jumps += 1;
  jumpsContainer.innerHTML = jumps;
};
let points = 0;
const pointsContainer = document.querySelector("#points>.value");
const addPoints = (value) => {
  points += value;
  pointsContainer.innerHTML = points;
};

const game = new PIXI.Application({
  width: CANVAS_SIZE,
  height: CANVAS_SIZE,
  backgroundColor: 0x333333,
  sharedTicker: true,
});
container.appendChild(game.view);

const BOX_SIZE = norm(30);
const rect = new PIXI.Graphics();
rect.beginFill(0xaa0000);
rect.lineStyle(3, 0xaa4444, 1);
rect.drawRect(
  CANVAS_SIZE / 3 - BOX_SIZE / 2,
  CANVAS_SIZE - BOX_SIZE - norm(20),
  BOX_SIZE,
  BOX_SIZE
);
rect.endFill();
game.stage.addChild(rect);

let platforms = [];
const createPlatform = (config) => {
  const last = (platforms[platforms.length - 1] ?? {}).rect;
  const bounds = last?.getBounds() ?? { x: 0, width: 0 };
  const startX = bounds.x + bounds.width + norm(config.gap);
  const p = new PIXI.Graphics();
  p.beginFill(0xaaaaaa);
  p.drawRect(startX, CANVAS_SIZE - norm(20), config.size, norm(20));
  game.stage.addChild(p);
  platforms.push({ ...config, rect: p });
  numberOfPlatforms += 1;
};
const createRandomPlatform = () => {
  createPlatform({
    gap: Math.random() * 100 + BOX_SIZE,
    size: Math.random() * CANVAS_SIZE + 2 * BOX_SIZE,
  });
};

createPlatform({ gap: 0, size: CANVAS_SIZE / 2 });
createPlatform({ gap: 50, size: 200 });
createRandomPlatform();

const direction = -1;
const gravity = 0.8;
const jumpAt = rect.y;

let jumping = false;
let timeStart = 0;

const powerup = () => {
  timeStart = Date.now();
};

const lose = () => {
  jumping = true;
  alertStatus("You lose!", () => {
    const maxPoints = localStorage.getItem("mtg_highscore") || 0;
    if (points > maxPoints) {
      localStorage.setItem("mtg_highscore", points);
      alertStatus(`new highscore: ${points}!`, () => location.reload());
    } else {
      location.reload();
    }
  });
};

const jump = () => {
  if (jumping) return;
  jumping = true;
  setStatus(null);
  addJump();

  const jumpPower = Math.min(20, (Date.now() - timeStart) / 20);
  let time = 0;

  const tick = (deltaMs) => {
    const jumpHeight = norm(
      (-gravity / 2) * Math.pow(time, 2) + jumpPower * time
    );

    platforms.forEach((p) => {
      p.rect.x -= norm(5);
      const bounds = p.rect.getBounds();
      if (bounds.x + p.rect.width < CANVAS_SIZE / 3 - BOX_SIZE / 2) {
        p.rect.destroy();
        platforms.shift();
        createRandomPlatform();
        return undefined;
      }
      return p;
    });

    if (jumpHeight < 0) {
      jumping = false;
      game.ticker.remove(tick);
      rect.y = jumpAt;
      if (platforms.length === 0) {
        lose();
      }
      const bounds = platforms[0].rect.getBounds();
      if (bounds.x + bounds.width < CANVAS_SIZE / 3 - BOX_SIZE / 2) lose();
      if (bounds.x > CANVAS_SIZE / 3 + BOX_SIZE / 2) lose();

      addPoints(Math.floor(jumpPower * (numberOfPlatforms / jumps)));
      return;
    }

    rect.y = jumpAt + jumpHeight * direction;
    time += deltaMs;
  };

  game.ticker.add(tick);
};

game.view.addEventListener("pointerdown", powerup);
game.view.addEventListener("pointerup", jump);
